<template>
    <div class="content">
      <notice label="cancel_page_tips"/>
      <div class="fillingArea flex">
          <div class="area">
            <el-form label-position="top" :model="userForm">
              <el-form-item label="用户名" prop="username">
                <div class="input"><el-input v-model="userForm.username" disabled></el-input></div>
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <div class="input"><el-input v-model="userForm.mobile" disabled></el-input></div>
              </el-form-item>
              <el-form-item label="联系人邮箱" prop="email">
                <div class="input"><el-input v-model="userForm.email" disabled></el-input></div>
              </el-form-item>
            </el-form>
          </div>
          <div class="area">
            <div class="areaTitle">您选择了{{ orderDetail.setmeal_name }}：<span>￥{{ orderDetail.price }}</span></div>
            <div class="desc">
                <div class="descTitle">套餐说明</div>
                <div class="descContent">{{ orderDetail.describe }}</div>
            </div>
            <div class="button">
              <el-button type="primary" class="buttonClass" @click="cancelOrder">我确认需要取消</el-button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import notice from '../components/Notice.vue'

export default {
  name: 'orderSubmit',
  components: {
    notice
  },
  data() {
    return {
      userForm: {
        username: '',
        mobile: '',
        email: ''
      },
      order_num: '',
      orderDetail: {}
    }
  },
  created() {
    this.order_num = this.$route.query.order_num
    this.getOrderDetail()
  },
  methods: {
    cancelOrder() {
      this.$messageBox.alert('确定取消订单吗?', '取消订单', {
        confirmButtonText: '确定',
        showCancelButton: true
      }).then(res => {
        this.$api.cancelOrder({ order_num: this.order_num }).then(res => {
          this.$message({
            message: '取消订单成功！',
            type: 'success'
          })
          setTimeout(() => {
            this.$router.replace('/home')
          }, 1000)
        })
      }).catch(() => {
        return false
      })
    },
    getOrderDetail() {
      this.$api.getOrderDetail({ order_num: this.order_num }).then(res => {
        this.orderDetail = res.info
        Object.keys(this.userForm).forEach(item => {
          this.userForm[item] = res.info[item]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    border: 1px solid #e3e3e3;
    .fillingArea {
      margin-top: 10px;
      width: 100%;
      .area {
        flex: 1;
        .input {
          width: 450px;
        }
        .areaTitle {
          font-size: 14px;
          line-height: 40px;
          padding-bottom: 10px;
          span {
            font-weight: bold;
            color: #409EFF;
          }
        }
        .desc {
          .descTitle {
            font-size: 18px;
          }
          .descContent {
            margin-top: 10px;
            height: 165px;
            overflow-y: auto;
            line-height: 20px;
          }
        }
        .button {
          margin: 22px 0;
          .buttonClass {
            width: 200px;
            height: 48px;
            &:first-child {
              width: 330px;
            }
          }
        }
      }
    }
  }
  .steps {
    .pencil {
        width: 70px;
        height: 8px;
        border-radius: 4px;
        background-color: #dcdcdc;
        margin-right: -5px;
    }
    .circle {
        width: 18px;
        height: 18px;
        background-color: #dcdcdc;
        border-radius: 50%;
    }
    span {
        font-size: 14px;
        margin-left: 4px;
        color: #dcdcdc;
    }
    .step {
        margin-right: 10px;
        &:first-child {
        .pencil, .circle {
            background-color: #abd155;
        }
        span {
            color: #333;
        }
    }
    }
}
</style>
